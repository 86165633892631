import { useSwiper } from 'swiper/react'
import useMountEffect from '@hooks/useMountEffect'

let interval: number | null = null
export const useSwiperAutoHeight = () => {
    const swiper = useSwiper()
    useMountEffect(() => {
        if (interval == null && !!swiper) {
            interval = window.setInterval(() => {
                try {
                    swiper.updateAutoHeight()
                } catch (e) {
                    // ignore
                }
            }, 50)
        }

        return () => {
            if (interval) {
                window.clearInterval(interval)
                interval = null
            }
        }
    })
}

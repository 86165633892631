import { Dispatch, SetStateAction } from 'react'
import { DateTimeSelectorTypes, GATrackingActions, GATrackingCategories } from '@util/enums'
import { useTranslation } from 'next-i18next'
import { useLocaleConfig } from '@contexts/config'
import { useSearch } from '@contexts/search'
import { useDateCalculation } from '@hooks/useDateCalculation'
import styled from 'styled-components'
import { CalendarIcon } from '@global/icons/Calendar'
import { ClockIcon } from '@components/global/icons/Clock'
import { ProductSelectorArrow } from '@components/global/icons/ProductSelectorArrow'
import { FlexDirection } from '../../types'
import { trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'

export const TimeFilterDaily = ({
    setActiveSpecTimeOption,
    handleCalendarModalClick,
    showIconForTime,
    showIconForDate,
    showArrow,
    direction = 'column',
    labelColor,
}: {
    setActiveSpecTimeOption: Dispatch<SetStateAction<DateTimeSelectorTypes | null>>
    handleCalendarModalClick: () => void
    showIconForTime?: boolean
    showIconForDate?: boolean
    showArrow?: boolean
    direction?: FlexDirection
    labelColor?: string
}) => {
    const { t } = useTranslation()
    const { isRTL, formatDate } = useLocaleConfig()
    const isDesktop = useMediaQuery(device.laptop)
    const { fromDate, toDate, toPickerTime, fromPickerTime } = useSearch()
    const { fillOutTimePicker } = useDateCalculation()
    const directionIsRow = direction === 'row'
    const { user } = useAuth()

    return (
        <Flex>
            <FlexItem direction={direction}>
                <InputWithLabelAndIcon noMargin={directionIsRow}>
                    <Label color={labelColor}>{t('dateTimeSelector.from', 'from')}</Label>

                    <InputButton
                        data-testid="fromDate"
                        onClick={() => {
                            handleCalendarModalClick()
                            trackEvent({
                                action: GATrackingActions.H_D_SELECT_DATE,
                                category: GATrackingCategories.DATE,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                    >
                        <DateTimeSelector className={isRTL ? 'dateTimeSelectorSpan' : ''}>
                            {formatDate(fromDate ?? new Date())}
                        </DateTimeSelector>
                        {showIconForDate && (
                            <span>
                                <CalendarIcon color={'var(--primaryColor)'} />
                            </span>
                        )}
                    </InputButton>
                </InputWithLabelAndIcon>
                <InputWithLabelAndIcon noMargin={directionIsRow}>
                    <InputButton
                        data-testid="fromTime"
                        onClick={() => {
                            trackEvent({
                                action: GATrackingActions.H_D_SELECT_DATE,
                                category: GATrackingCategories.DATE,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                            setActiveSpecTimeOption(DateTimeSelectorTypes.from)
                            fillOutTimePicker(DateTimeSelectorTypes.from)
                        }}
                        fontWeight={400}
                    >
                        <p style={{ whiteSpace: 'nowrap' }}>{fromPickerTime?.displayTimeRange}</p>
                        {showIconForTime && (
                            <span>
                                <ClockIcon />
                            </span>
                        )}
                    </InputButton>
                </InputWithLabelAndIcon>
            </FlexItem>
            {showArrow && (
                <FlexItem justify="flex-end" alignItems="center">
                    <IconBox>
                        <ProductSelectorArrow />
                    </IconBox>
                </FlexItem>
            )}
            <FlexItem direction={direction}>
                <InputWithLabelAndIcon noMargin={directionIsRow}>
                    <Label color={labelColor}>{t('dateTimeSelector.to', 'to')}</Label>
                    <InputButton
                        data-testid="toDate"
                        onClick={() => {
                            handleCalendarModalClick()
                            trackEvent({
                                action: GATrackingActions.H_D_SELECT_DATE,
                                category: GATrackingCategories.DATE,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                    >
                        <DateTimeSelector className={isRTL ? 'dateTimeSelectorSpan' : ''}>
                            {formatDate(toDate ?? new Date())}
                        </DateTimeSelector>
                        {showIconForDate && (
                            <span>
                                <CalendarIcon color={'var(--primaryColor)'} />
                            </span>
                        )}
                    </InputButton>
                </InputWithLabelAndIcon>
                <InputWithLabelAndIcon noMargin={directionIsRow}>
                    <InputButton
                        data-testid="toTime"
                        onClick={() => {
                            setActiveSpecTimeOption(DateTimeSelectorTypes.to)
                            fillOutTimePicker(DateTimeSelectorTypes.to)
                            trackEvent({
                                action: GATrackingActions.H_D_SELECT_DATE,
                                category: GATrackingCategories.DATE,
                                value: {
                                    userId: user?.userId || '',
                                    careemId: user?.careemId || '',
                                    isDesktop: isDesktop,
                                },
                            })
                        }}
                        fontWeight={400}
                    >
                        <p style={{ whiteSpace: 'nowrap' }}>{toPickerTime?.displayTimeRange}</p>
                        {showIconForTime && (
                            <span>
                                <ClockIcon />
                            </span>
                        )}
                    </InputButton>
                </InputWithLabelAndIcon>
            </FlexItem>
        </Flex>
    )
}

export const FlexItem = styled.div<{ direction?: string; justify?: string; alignItems?: string }>`
    display: flex;
    flex-direction: ${({ direction = 'column' }) => direction};
    ${({ direction }) => direction === 'row' && 'gap: 16px'};
    ${({ justify }) => justify && `justify-content: ${justify}`};
    ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
    flex: 1 auto;
`

export const Flex = styled.div<{ direction?: string }>`
    display: flex;
    flex-direction: ${({ direction = 'row' }) => direction};
    justify-content: center;
    gap: 1rem;
    width: 100%;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

const IconBox = styled.div`
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const InputWithLabelAndIcon = styled.div<{ withoutLabel?: boolean; noMargin?: boolean }>`
    width: 100%;
    margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '1rem')};
    min-width: 120px;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    ${({ withoutLabel = false }) => (withoutLabel ? 'padding-top: 22px' : '0')};
`

export const Label = styled.label<{ color?: string }>`
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    color: ${({ color = 'rgba(0, 0, 0, 0.6)' }) => color};
    line-height: 14px;
    padding-bottom: 8px;
`

export const InputButton = styled.button<{ fontWeight?: number }>`
    width: 100%;
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    background: var(--primaryBackgroundColor);
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: ${(props) => props.fontWeight};
    font-size: 16px;

    svg {
        flex: 1;
    }

    &:focus {
        border: 1px solid red;
        color: var(--primaryColor);
    }
`

const DateTimeSelector = styled.p`
    white-space: nowrap;
`
InputButton.defaultProps = {
    fontWeight: 700,
}

import { zonedTimeToUtc } from 'date-fns-tz'
import { isAfter, isBefore, subHours } from 'date-fns'
import { useLocaleConfig } from '@contexts/config'
import { useFeature } from '@hooks/useFeatures'
import { LAST_HOUR_HANDBACK_DATE_CHANGE } from '@util/featureFlags'
import { StatusType } from '@service/booking.types'
import { ProductType } from '@service/configuration.types'

type BookingDetails = {
    handbackTime?: string
    handoverTime: string
    bookingStatus: StatusType
    productType: ProductType
}

export function useHandbackDateChangeAllowed({
    handbackTime,
    handoverTime,
    bookingStatus,
    productType,
}: BookingDetails) {
    const { citySettings } = useLocaleConfig()
    const { enabled: isLastHourHandbackDateChangeEnabled } = useFeature(LAST_HOUR_HANDBACK_DATE_CHANGE)
    const handbackChangeBeforeLimitHours =
        typeof citySettings?.handbackChangeBeforeLimitHours !== 'undefined'
            ? citySettings?.handbackChangeBeforeLimitHours
            : 1

    const deliveryDate = new Date(handoverTime)
    const now = new Date()
    const isHandbackDateChangeAllowed =
        bookingStatus === StatusType.ACTIVE ||
        bookingStatus === StatusType.APPROVED ||
        bookingStatus === StatusType.PENDING_APPROVAL
    const UTCReturnDate = handbackTime
        ? zonedTimeToUtc(handbackTime, citySettings?.timezone ?? 'Asia/Dubai')
        : undefined

    // The booking has not yet started for a DAILY booking
    if (!UTCReturnDate && productType === ProductType.DAILY) return false
    if (isBefore(now, deliveryDate)) return isHandbackDateChangeAllowed
    // The booking is in progress

    if (isLastHourHandbackDateChangeEnabled) {
        return (
            isHandbackDateChangeAllowed &&
            isAfter(now, deliveryDate) &&
            (!UTCReturnDate || isBefore(now, subHours(UTCReturnDate, handbackChangeBeforeLimitHours)))
        )
    } else {
        return (
            isHandbackDateChangeAllowed &&
            isAfter(now, deliveryDate) &&
            (!UTCReturnDate || isBefore(now, subHours(UTCReturnDate, 1)))
        )
    }
}

import { useEffect, useState } from 'react'

export function useCookie(cookieName: string): string | null {
    const [cookieValue, setCookieValue] = useState<string | null>(null)

    useEffect(() => {
        const getCookieValue = () => {
            const allCookies = document.cookie.split('; ')
            const cookie = allCookies.find((c) => c.startsWith(cookieName + '='))
            return cookie ? cookie.split('=')[1] : null
        }
        const initialValue = getCookieValue()
        setCookieValue(initialValue)
    }, [cookieName]) // Run the effect only when the cookieName changes

    return cookieValue
}

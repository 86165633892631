import styled from 'styled-components'
import { useGlobalState } from '@contexts/global'
import { useTranslation } from 'next-i18next'
import { ProductType } from '@service/configuration.types'
import { useCallback, useEffect, useState } from 'react'
import { DateTimeSelectorTypes, GATrackingActions, GATrackingCategories } from '@util/enums'
import { TimeFilterMonthly } from '@components/modules/Search/MobileBookingQuickFilter/components/TimeFilterMonthly'
import { TimeFilterDaily } from '@components/modules/Search/MobileBookingQuickFilter/components/TimeFilterDaily'
import { device } from '@util/responsive'
import { useProductSwitcher } from './useProductSwitcher'
import dynamic from 'next/dynamic'
import { TimeSelector } from '@components/global/TimeSelector'
import Datepicker from '../Calendar/Datepicker'
import { trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'
import { useMediaQuery } from '@hooks/mediaQuery'
import { useCommon } from '@contexts/common'

const Filter = dynamic(() => import('@components/modules/Car/Filter').then(({ Filter }) => Filter), {
    ssr: false,
})

export const DesktopProductSwitcher = (): JSX.Element => {
    const { t } = useTranslation()
    const { bookingState } = useGlobalState()
    const isDesktop = useMediaQuery(device.laptop)
    const { changeProductTo } = useCommon()
    const { user } = useAuth()
    const [isCalendarModalOpened, setIsCalendarModalOpened] = useState(false)
    const [activeSpecTimeOption, setActiveSpecTimeOption] = useState<DateTimeSelectorTypes | null>(null)
    const { onTabSelected } = useProductSwitcher()

    const selectedProductType = bookingState.productType
    const isDaily = selectedProductType === ProductType.DAILY

    const handleCalendarModalClick = useCallback(() => {
        if (!isCalendarModalOpened) setIsCalendarModalOpened(true)
    }, [isCalendarModalOpened, setIsCalendarModalOpened])

    useEffect(() => {
        changeProductTo(ProductType.DAILY)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])

    return (
        <MainWrapper>
            <Wrapper>
                <MainTitle>{t('timeFilterWidget.desktopTitle', 'How long would you like to rent?')}</MainTitle>
                <ProductSwitcher>
                    <ProductSelectionTabsWrapper>
                        <ProductSelectionTab
                            active={isDaily}
                            onClick={() => {
                                onTabSelected(ProductType.DAILY)
                                trackEvent({
                                    action: GATrackingActions.H_DAILY_CLICK,
                                    category: GATrackingCategories.HOMETAB,
                                    value: {
                                        userId: user?.userId || '',
                                        careemId: user?.careemId || '',
                                        isDesktop: isDesktop,
                                    },
                                })
                            }}
                        >
                            <Tab>
                                <TabTitle active={isDaily}>{t('timeFilterWidget.tabTitleLeft', 'short term')}</TabTitle>
                                <TabSubTitle>{t('timeFilterWidget.tabSubTitleLeft', 'daily rental')}</TabSubTitle>
                            </Tab>
                        </ProductSelectionTab>
                        <ProductSelectionTab
                            active={!isDaily}
                            onClick={() => {
                                onTabSelected(ProductType.MONTHLY)
                                trackEvent({
                                    action: GATrackingActions.H_MONTHLY_CLICK,
                                    category: GATrackingCategories.HOMETAB,
                                    value: {
                                        userId: user?.userId || '',
                                        careemId: user?.careemId || '',
                                        isDesktop: isDesktop,
                                    },
                                })
                            }}
                        >
                            <Tab>
                                <TabTitle active={!isDaily}>
                                    {t('timeFilterWidget.tabTitleRight', 'long term')}
                                </TabTitle>
                                <TabSubTitle>{t('timeFilterWidget.tabSubTitleRight', 'monthly rental')}</TabSubTitle>
                            </Tab>
                        </ProductSelectionTab>
                    </ProductSelectionTabsWrapper>
                    <FilterContent>
                        {isDaily && (
                            <>
                                <TimeFilterDaily
                                    setActiveSpecTimeOption={setActiveSpecTimeOption}
                                    handleCalendarModalClick={handleCalendarModalClick}
                                    showIconForDate
                                    showIconForTime
                                    direction="row"
                                    labelColor="white"
                                    showArrow
                                />
                            </>
                        )}
                        {!isDaily && (
                            <TimeFilterMonthly
                                type={DateTimeSelectorTypes.from}
                                label={t('timeFilterWidget.handover', 'Handover')}
                                handleCalendarModalClick={handleCalendarModalClick}
                                labelColor="white"
                            />
                        )}
                    </FilterContent>
                    {isCalendarModalOpened && (
                        <Datepicker setTimeModal={setIsCalendarModalOpened} isSingleDaySelectionMode={!isDaily} />
                    )}
                    {activeSpecTimeOption && (
                        <TimeSelector
                            specTime={activeSpecTimeOption}
                            setTimeModal={() => setActiveSpecTimeOption(null)}
                        />
                    )}
                    <Filter />
                </ProductSwitcher>
            </Wrapper>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
    background: none;
    padding-bottom: 32px;
    padding-top: 10px;
    position: relative;
`

const Wrapper = styled.div<{ maxWidth?: string }>`
    max-width: ${({ maxWidth = '818px' }) => maxWidth};
    margin: auto;
    padding-inline: var(--desktopPadding);
    padding-top: 20px;
`

const ProductSwitcher = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    position: relative;
`

const ProductSelectionTabsWrapper = styled.div`
    display: flex;
    justify-content: center;
    z-index: 10;
    position: relative;
`

export const ProductSelectionTab = styled.div<{ active: boolean }>`
    display: flex;
    width: 20%;
    background: ${(props) =>
        props.active ? 'linear-gradient(1deg, var(--primaryColor) 17%, #ff7b67 90%)' : 'rgb(255,65,65,0.1)'};
    color: ${(props) => (props.active ? 'white' : 'black')};
    padding: 8px var(--padding);
    transition: all cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.2s;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    border-radius: 10px 10px 0 0;
    font-weight: 800;
    text-transform: uppercase;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    cursor: pointer;
`
export const Tab = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const TabTitle = styled.div<{ active: boolean }>`
    font-size: var(--size-16);
    font-weight: ${({ active }) => (active ? 'var(--weight-extraBold)' : 'var(--weight-extraRegular)')};

    @media ${device.tablet} {
        font-size: var(--size-20);
    }
`
export const TabSubTitle = styled.div`
    font-size: var(--size-12);
    font-weight: var(--weight-semiBold);

    @media ${device.tablet} {
        font-size: var(--size-10);
    }
`

const FilterContent = styled.div`
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    border-radius: 8px;
    column-gap: 1rem;
    padding: 16px;
`

const MainTitle = styled.h3`
    display: flex;
    justify-content: center;
    color: var(--primaryColor);
    margin: 0 0 32px 0;
    font-weight: 800;
    font-size: 30px;
`

import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import React from 'react'
import { useLocaleConfig } from '@contexts/config'
import { City, CityDetails } from '@util/enums'
import { META_DATA } from '@util/constants'

export function MetaTags({ pageType = 'base', canonical }) {
    const { t } = useTranslation()
    const { city } = useLocaleConfig()
    const cityName = t(`city.${city}.name`, CityDetails[city]?.name || CityDetails[City.dubai].name)
    const title = t(`meta.${pageType}.title`, META_DATA.title)
    const description = t(`meta.${pageType}.description`, META_DATA.description)
    const keywords = t(
        `meta.${pageType}.keywords`,
        `rent a car, car rental, car hire, car rental in ${cityName}, ${cityName} car rental, ${cityName} car hire`,
    )

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="Swapp" />
            <link rel="canonical" href={canonical} />
        </Head>
    )
}

export function ListingMetaTags({ carName = 'a car' }) {
    const { t } = useTranslation()
    const { city } = useLocaleConfig()
    const cityName = t(`city.${city}.name`, CityDetails[city]?.name || CityDetails[City.dubai].name)
    const title = t(`meta.listing.title`, {
        defaultValue: `Rent {{carName}} in ${cityName}! | Swapp`,
        carName: carName,
    })
    const description = t(`meta.listing.description`, {
        defaultValue: `Rent {{carName}} in ${cityName} at the best rates. No deposit, wide selection of configurations, convenient locations, and hassle-free booking. Explore ${cityName} with ease!`,
        carName,
        city: cityName,
    })
    const keywords = t(`meta.listing.keywords`, {
        defaultValue: `rent a car, {{carName}} rental, car hire, car rental in ${cityName}, {{carName}} rental in ${cityName}, ${cityName} car rental, ${cityName} car hire`,
        carName,
    })
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="Swapp" />
        </Head>
    )
}

import { useEffect, useMemo, useReducer } from 'react'
import { EngineType } from '@service/booking.types'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'
import { CarFilterActions } from '@util/enums'

type CarFuelTypesFilterAction =
    | {
          type: CarFilterActions.set | CarFilterActions.init
          value: string[]
      }
    | {
          type: CarFilterActions.reset
      }

const filterReducer = (state: { value: string[] }, action: CarFuelTypesFilterAction) => {
    switch (action.type) {
        case CarFilterActions.set:
            return {
                ...state,
                value: action.value,
            }
        case CarFilterActions.init:
            return { value: action.value }
        case CarFilterActions.reset:
            return { value: [] }

        default:
            return state
    }
}

export const useCarFuelTypesFilterValues = (carFuelType?: string[]) => {
    const [filter, dispatch] = useReducer(filterReducer, { value: carFuelType || [] })

    const carFuelTypeConfig = useDeepCompareMemo(() => carFuelType, [carFuelType])

    useEffect(() => {
        carFuelTypeConfig && dispatch({ type: CarFilterActions.set, value: carFuelTypeConfig })
    }, [carFuelTypeConfig])

    const engineTypes = useMemo(
        () => Object.keys(EngineType).map((engineType) => ({ id: engineType, name: engineType })),
        [],
    )

    return {
        carFuelFiltered: filter.value,
        carFuelDispatch: dispatch,
        carFuelData: engineTypes,
    }
}

import React from 'react'
import { Booking } from '@service/booking.types'
import { useTranslation } from 'next-i18next'
import { ClockIcon } from '@global/icons/Clock'
import { displayTimeRange, getDefaultExtraHours } from '@util/functions'
import { MapPin } from '@global/icons/MapPin'
import Link from 'next/link'
import { WrenchIcon } from '@global/icons/Wrench'
import {
    Badge,
    BadgeWrapper,
    Body,
    BoldText,
    Bottom,
    CarComponent,
    Column,
    CtaButton,
    DateRow,
    DateSection,
    DesktopWrapper,
    getFormattedAddress,
    Message,
    Row,
    Separator,
    Text,
    Title,
} from '@components/CurrentBookingsWidget/styles'
import { useLocaleConfig } from '@contexts/config'
import { getGoogleMapLink } from '.'
import { ExtendButton } from '@components/CurrentBookingsWidget/ExtendButton'
import { maxWidthOfTheDateRange } from './PendingBookingDesktopView'

export const ActiveBookingDesktopView: React.FC<{ booking: Booking; isD2D: boolean }> = ({ booking, isD2D }) => {
    const { t } = useTranslation()
    const { city, isRTL } = useLocaleConfig()
    const address = isD2D && booking.d2dHandbackAddress ? booking.d2dHandbackAddress : booking.selfPickupHandbackAddress
    const formattedAddress = getFormattedAddress(address)
    const handbackTime = booking.handbackTime ? new Date(booking.handbackTime) : undefined
    return (
        <DesktopWrapper>
            <Column isRTL={isRTL}>
                <Title>
                    <Link href={`/${city}/booking/${booking.bookingId}`} legacyBehavior>
                        <a>
                            <span>{t('bookingWidget.bookingId', 'Booking ID')}:</span>
                            <span> </span>
                            <span data-testid="booking-widget_booking-id">{booking.bookingId}</span>
                        </a>
                    </Link>
                </Title>
                <Body>
                    <DateRow maxWidth={maxWidthOfTheDateRange}>
                        <DateSection date={booking.handoverTime} dataTestId="booking-widget_handover-time" />
                        <Separator />
                        <DateSection date={booking.handbackTime} dataTestId="booking-widget_handback-time" />
                    </DateRow>
                    <CarComponent carDetails={booking.carDetails} />
                </Body>
                <Bottom>
                    {booking.roadsideAssistanceNumber && (
                        <CtaButton href={`tel:${booking.roadsideAssistanceNumber}`} color={'var(--primaryColor)'}>
                            <WrenchIcon />
                            {t('bookingWidget.roadsideAssistance', 'Roadside Assistance')}
                        </CtaButton>
                    )}
                    <ExtendButton booking={booking} city={city} />
                </Bottom>
            </Column>
            <Column isRTL={isRTL}>
                <BadgeWrapper isRTL={isRTL}>
                    <Badge>{t('bookingWidget.bookingActive', 'Booking active')}</Badge>
                </BadgeWrapper>
                <Title data-testid="booking-widget_delivery-type">{t('bookingWidget.handback', 'Handback')}</Title>
                <Body>
                    {handbackTime && (
                        <Row>
                            <ClockIcon color={'var(--primaryColor)'} />{' '}
                            <BoldText data-testid="booking-widget_handback-time">
                                {displayTimeRange(handbackTime, getDefaultExtraHours(isD2D)) ||
                                    t('customerBookingWidgetNotSet', 'not set')}
                            </BoldText>
                        </Row>
                    )}
                    <Row>
                        <MapPin />
                        <Link href={getGoogleMapLink(address.latitude, address.longitude)}>
                            <Text>{formattedAddress}</Text>
                        </Link>
                    </Row>
                </Body>
                <Bottom>
                    <Message>{t('bookingWidget.enjoyYorRide', 'Enjoy your ride!')}</Message>
                </Bottom>
            </Column>
        </DesktopWrapper>
    )
}

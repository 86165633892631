import { Country } from '@util/enums'
import { paymentService } from '@service/payment'
import { useAuth } from '@contexts/auth'
import { ProductType } from '@service/configuration.types'
import { getCountryByCity } from '@util/localization'
import { useCallback } from 'react'

type ExtendableUtilsType = {
    isShowWarningWindow: (bookingId: number, productType: ProductType) => Promise<boolean>
}

export const useBookingExtendableUtils = (): ExtendableUtilsType => {
    const { user } = useAuth()

    const isShowWarningWindow = useCallback(
        async (bookingId: number, productType: ProductType) => {
            const respData = await paymentService.getRentalDetails(user?.userId as string, bookingId, productType)
            if (!respData?.data) return false
            return getCountryByCity(respData?.data.cityCode) === Country.UAE && productType === ProductType.DAILY
        },
        [user?.userId],
    )

    return { isShowWarningWindow }
}

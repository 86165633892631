import { useEffect, useMemo, useReducer } from 'react'
import { useQuery } from 'react-query'
import { CarFilterActions, CityCode } from '@util/enums'
import { defaultCityCode } from '@util/config'
import { ProductType } from '@service/configuration.types'
import { useLocaleConfig } from '@contexts/config'
import { bookingService } from '@service/booking'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'
import { CarFeature } from '@service/booking.types'

export type CarFeatureFilter = { i18nKey: string; id: number; value?: boolean }

type CarFeaturesFilterValuesAction =
    | {
          type: CarFilterActions.set
          id: number
          value?: boolean
      }
    | {
          type: CarFilterActions.init
          value: CarFeatureFilter[]
      }
    | {
          type: CarFilterActions.reset
      }

const filterReducer = (state: CarFeatureFilter[], action: CarFeaturesFilterValuesAction) => {
    switch (action.type) {
        case CarFilterActions.set: {
            const newState = [...state]
            const index = newState.findIndex((x) => x.id === action.id)
            if (index > -1) newState[index].value = action.value

            return newState
        }
        case CarFilterActions.init:
            return action.value
        case CarFilterActions.reset: {
            return state.map((x) => ({ ...x, value: false }))
        }
        default:
            return state
    }
}

const init = (initIds?: string[], carFeatures?: CarFeature[]): CarFeatureFilter[] => {
    {
        if (carFeatures && initIds && carFeatures?.length > 0) {
            return carFeatures.map((x: CarFeature) => ({
                ...x,
                value: initIds?.includes(x.id.toString()),
            }))
        }

        return []
    }
}

export const useCarFeaturesFilterValues = (productType?: ProductType, initCarFeatures?: string[]) => {
    const { citySettings } = useLocaleConfig()

    const cityCode = CityCode[citySettings?.cityCode || defaultCityCode]
    const { data: carFeatures } = useQuery(['carFeatures', productType, cityCode], () =>
        bookingService.getCarFeatures({
            cityCode: cityCode,
            productType: productType || ProductType.DAILY,
        }),
    )

    const carFeaturesConfig = useDeepCompareMemo(
        () => init(initCarFeatures, carFeatures?.data),
        [initCarFeatures, carFeatures?.data],
    )

    const [filter, dispatch] = useReducer(filterReducer, carFeaturesConfig)

    useEffect(() => {
        carFeaturesConfig && dispatch({ type: CarFilterActions.init, value: carFeaturesConfig })
    }, [carFeaturesConfig])

    const filteredValues = useMemo(
        () => filter.filter((feature: CarFeatureFilter) => feature.value).map((x) => x.id),
        [filter],
    )

    const queryValues = useMemo(
        () => filter.filter((feature: CarFeatureFilter) => feature.value) as CarFeatureFilter[],
        [filter],
    )

    return {
        carFeaturesFiltered: filteredValues,
        carFeaturesDispatch: dispatch,
        carFeaturesData: filter,
        carFeaturesQuery: queryValues,
    }
}

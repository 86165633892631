export const MobileFilterIcon: React.FC = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.33325 2.06667C1.33325 1.6933 1.33325 1.50661 1.40591 1.36401C1.46983 1.23856 1.57182 1.13658 1.69726 1.07266C1.83987 1 2.02655 1 2.39992 1H13.5999C13.9733 1 14.16 1 14.3026 1.07266C14.428 1.13658 14.53 1.23856 14.5939 1.36401C14.6666 1.50661 14.6666 1.6933 14.6666 2.06667V2.51293C14.6666 2.69213 14.6666 2.78173 14.6447 2.86504C14.6253 2.93887 14.5933 3.00882 14.5503 3.07184C14.5016 3.14295 14.4339 3.20164 14.2985 3.319L10.0347 7.01434C9.89924 7.1317 9.83153 7.19038 9.78291 7.26149C9.73983 7.32451 9.70789 7.39446 9.68848 7.46829C9.66659 7.55161 9.66659 7.64121 9.66659 7.8204V11.3056C9.66659 11.436 9.66659 11.5011 9.64556 11.5575C9.62698 11.6073 9.59676 11.6519 9.55742 11.6877C9.5129 11.7281 9.45238 11.7524 9.33133 11.8008L7.06466 12.7074C6.81963 12.8054 6.69712 12.8545 6.59877 12.834C6.51276 12.8161 6.43729 12.765 6.38875 12.6918C6.33325 12.6081 6.33325 12.4762 6.33325 12.2122V7.8204C6.33325 7.64121 6.33325 7.55161 6.31135 7.46829C6.29195 7.39446 6.26001 7.32451 6.21692 7.26149C6.1683 7.19038 6.1006 7.1317 5.96518 7.01434L1.70133 3.319C1.56591 3.20164 1.4982 3.14295 1.44958 3.07184C1.4065 3.00882 1.37455 2.93887 1.35515 2.86504C1.33325 2.78173 1.33325 2.69213 1.33325 2.51293V2.06667Z"
                stroke="#44505C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

import { useEffect, useReducer } from 'react'
import { useQuery } from 'react-query'
import { defaultCityCode } from '@util/config'
import { ProductType } from '@service/configuration.types'
import { useLocaleConfig } from '@contexts/config'
import { bookingService } from '@service/booking'
import { CarFilterActions, CityCode } from '@util/enums'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'

type CarModelFilterAction =
    | {
          type: CarFilterActions.set | CarFilterActions.init
          value: number[]
      }
    | {
          type: CarFilterActions.reset
      }

const filterReducer = (state: { value: number[] }, action: CarModelFilterAction) => {
    switch (action.type) {
        case CarFilterActions.set:
            return {
                ...state,
                value: action.value,
            }
        case CarFilterActions.init:
            return { value: action.value }
        case CarFilterActions.reset:
            return { value: [] }
        default:
            return state
    }
}

export const useCarModelFilterValues = (productType?: ProductType, carModel?: number[], makes?: number[]) => {
    const { citySettings } = useLocaleConfig()

    const cityCode = CityCode[citySettings?.cityCode as string] || defaultCityCode
    const { data: models } = useQuery(
        ['models', makes, productType, cityCode],
        () =>
            bookingService.getModelsForMakes({
                cityCode: cityCode,
                productType: productType || ProductType.DAILY,
                makeIds: makes || [],
            }),
        { enabled: (makes || []).length > 0 },
    )

    const carModelConfig = useDeepCompareMemo(() => carModel, [carModel])

    const [filter, dispatch] = useReducer(filterReducer, { value: carModel || [] })

    useEffect(() => {
        carModelConfig && dispatch({ type: CarFilterActions.init, value: carModelConfig })
    }, [carModelConfig])

    return {
        carModelFiltered: filter.value.map((x) => +x),
        carModelDispatch: dispatch,
        carModelData: models?.data,
    }
}

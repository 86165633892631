import React from 'react'
import { Booking } from '@service/booking.types'
import { Card } from '@global/Card'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'
import { PendingBookingDesktopView } from './PendingBookingDesktopView'
import { PendingBookingMobileView } from './PendingBookingMobileView'
import { useSwiperAutoHeight } from './hooks'

export const PendingBookingWidget: React.FC<{ booking: Booking; isD2D: boolean }> = ({ booking, isD2D }) => {
    const isDesktop = useMediaQuery(device.laptop)
    useSwiperAutoHeight()
    return (
        <>
            {isDesktop ? (
                <Card>
                    <PendingBookingDesktopView booking={booking} isD2D={isD2D} />
                </Card>
            ) : (
                <PendingBookingMobileView booking={booking} isD2D={isD2D} />
            )}
        </>
    )
}

import { ColorSvg } from '@global/icons/Svg'

export const CalendarAddIcon = ({ color }: { color: string }) => {
    return (
        <ColorSvg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
        >
            <path
                d="M16.5 11C15.9477 11 15.5 11.4477 15.5 12V15.5H12C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5H15.5V21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21V17.5H21C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5H17.5V12C17.5 11.4477 17.0523 11 16.5 11Z"
                fill="currentColor"
            />
            <path
                d="M18 3H19C20.1 3 21 3.9 21 5V10C21 10.5523 20.5523 11 20 11C19.4477 11 19 10.5523 19 10V8H5V18C5 18.55 5.45 19 6 19H10C10.5523 19 11 19.4477 11 20C11 20.5523 10.5523 21 10 21H5C3.89 21 3 20.1 3 19L3.01 5C3.01 3.9 3.89 3 5 3H6V2C6 1.45 6.45 1 7 1C7.55 1 8 1.45 8 2V3H16V2C16 1.45 16.45 1 17 1C17.55 1 18 1.45 18 2V3Z"
                fill="currentColor"
            />
        </ColorSvg>
    )
}

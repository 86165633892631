import { isEqual } from 'lodash'
import { useRef, useMemo, DependencyList } from 'react'

const useDeepCompareMemoize = (value: any) => {
    const ref = useRef()
    if (!isEqual(value, ref.current)) {
        ref.current = value
    }
    return ref.current
}

export const useDeepCompareMemo = <T>(callback: () => T, dependencies: DependencyList): ReturnType<typeof useMemo<T>> =>
    // the callback dep is not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo<T>(callback, useDeepCompareMemoize(dependencies))

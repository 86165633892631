import React from 'react'
import { Booking } from '@service/booking.types'
import { Card } from '@global/Card'
import { useSwiperAutoHeight } from '@components/CurrentBookingsWidget/hooks'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'
import { ActiveBookingDesktopView } from './ActiveBookingDesktopView'
import { ActiveBookingMobileView } from './ActiveBookingMobileView'

export const ActiveBookingWidget: React.FC<{ booking: Booking; isD2D: boolean }> = ({ booking, isD2D }) => {
    const isDesktop = useMediaQuery(device.laptop)
    useSwiperAutoHeight()
    return (
        <>
            {isDesktop ? (
                <Card>
                    <ActiveBookingDesktopView booking={booking} isD2D={isD2D} />
                </Card>
            ) : (
                <ActiveBookingMobileView booking={booking} isD2D={isD2D} />
            )}
        </>
    )
}

import { Modal } from '@global/Modal'
import { CtaButton } from '@components/CurrentBookingsWidget/styles'
import { CalendarAddIcon } from '@global/icons/CalendarAdd'
import { useFreshChat } from '@hooks/useFreshChat'
import React from 'react'
import { differenceInHours } from 'date-fns'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useTracking } from '@hooks/useTracking'
import { useLocaleConfig } from '@contexts/config'
import { zonedTimeToUtc } from 'date-fns-tz'
import { City } from '@util/enums'
import { useRouter } from '@hooks/useRouter'
import { useFeature } from '@hooks/useFeatures'
import { LAST_HOUR_HANDBACK_DATE_CHANGE } from '@util/featureFlags'
import { useHandbackDateChangeAllowed } from '@hooks/useHandbackDateChange'
import { Booking } from '@service/booking.types'
import { useBookingExtendableUtils } from '@hooks/useBookingExtendableUtils'

const ModalWrapper = styled.div`
    z-index: 50;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 3rem;
    height: 100vh;
`

const ModalHeader = styled.h3`
    text-align: center;
`

const ContentWrapper = styled.div`
    background: white;
    padding: 2rem;
    border-radius: 16px;
    text-align: center;
    max-width: 75vw;
    margin: 2rem auto;
    @media (min-width: 1024px) {
        max-width: 500px;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: stretch;
    margin-top: 1rem;
    gap: 16px;
`

const Button = styled(CtaButton)`
    padding: 0 10px;
    width: 100%;
`

const ExtendWarning = styled.div`
    background: rgba(255, 88, 35, 0.1);
    border-radius: 12px;
    padding: 12px;
    font-size: 14px;
    margin: 20px 0;
    width: 100%;
`

export const ExtendButton = ({ booking }: { city: City; booking: Booking }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const { t } = useTranslation()
    const { trackExtendBooking } = useTracking()
    const { pushWithCity } = useRouter()
    const { citySettings } = useLocaleConfig()
    const { isShowWarningWindow } = useBookingExtendableUtils()
    const { enabled: isLastHourHandbackDateChange } = useFeature(LAST_HOUR_HANDBACK_DATE_CHANGE)
    const { openFreshChat } = useFreshChat()
    const zonedTime =
        booking.handbackTime && zonedTimeToUtc(booking.handbackTime, citySettings?.timezone ?? 'Asia/Dubai')

    const redirectToBooking = () => pushWithCity(`/booking/${booking.bookingId}?extend=true`)
    const isExtensionAllowed = useHandbackDateChangeAllowed({
        handbackTime: booking.handbackTime as string,
        handoverTime: booking.handoverTime as string,
        bookingStatus: booking.status,
        productType: booking.productType,
    })

    if (!isExtensionAllowed) {
        return (
            <ExtendWarning>
                {t(
                    'bookingWidget.extendNotPossible',
                    'Booking collection has already started. Please contact customer care for any modifications.',
                )}
            </ExtendWarning>
        )
    }

    return (
        <>
            <CtaButton
                onClick={async () => {
                    trackExtendBooking()
                    const showWindow = await isShowWarningWindow(booking.bookingId, booking.productType)
                    if (zonedTime && differenceInHours(zonedTime, new Date()) < 1 && showWindow) {
                        setIsModalOpen(true)
                    } else {
                        redirectToBooking()
                    }
                }}
            >
                <CalendarAddIcon color={'var(--white)'} />
                {t('bookingWidget.extend', 'Extend')}
            </CtaButton>
            {isModalOpen && (
                <Modal wrapperComponent={ModalWrapper} setModal={setIsModalOpen}>
                    <ContentWrapper>
                        <ModalHeader>
                            {isLastHourHandbackDateChange
                                ? t('bookingWidget.lastHourExtend.heading', 'Charge for Late Changes')
                                : t('bookingWidget.lastHourExtend.heading', 'Unable to extend booking in last hour')}
                        </ModalHeader>
                        <div>
                            {isLastHourHandbackDateChange
                                ? t(
                                      'bookingWidget.lastHourExtend.message',
                                      'You will be charged an extra 45 AED since we have already started processing your car collection. Would you like to continue?',
                                  )
                                : t(
                                      'bookingWidget.lastHourExtend.message',
                                      'Sorry, please contact customer care for modifications in the last hour',
                                  )}
                        </div>
                        <ButtonWrapper>
                            {isLastHourHandbackDateChange ? (
                                <Button color={'var(--primaryColor)'} onClick={() => setIsModalOpen(false)}>
                                    {t('bookingWidget.cancel', 'Cancel')}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        color={'var(--green)'}
                                        onClick={() => {
                                            openFreshChat()
                                            setIsModalOpen(false)
                                        }}
                                    >
                                        {t('bookingWidget.contactSupport', 'Contact Care')}
                                    </Button>
                                    <Button color={'var(--primaryColor)'} onClick={() => setIsModalOpen(false)}>
                                        {t('bookingWidget.cancel', 'Cancel')}
                                    </Button>
                                </>
                            )}
                            {isLastHourHandbackDateChange && (
                                <Button
                                    color={'var(--green)'}
                                    onClick={() => {
                                        setIsModalOpen(false)
                                        redirectToBooking()
                                    }}
                                >
                                    {t('bookingWidget.ok', 'OK')}
                                </Button>
                            )}
                        </ButtonWrapper>
                    </ContentWrapper>
                </Modal>
            )}
        </>
    )
}

import { trackEvent } from '@util/ga'
import { CarType } from '@service/booking.types'

export const submitFilterToGA = (filterParams) => {
    const { carSize, carType, minPrice, maxPrice } = filterParams
    // price
    if (minPrice && maxPrice) {
        trackEvent({
            action: 'Filters-price',
            category: 'BrowseDR',
            value: `${minPrice}-${maxPrice}`,
        })
    }
    // type
    if (carType) {
        trackEvent({
            action: 'Filters-type',
            category: 'BrowseDR',
            value: `${carType.toString()}`,
        })
    }
    // size
    if (carSize) {
        trackEvent({
            action: 'Filters-size',
            category: 'BrowseDR',
            value: `${carSize.toString()}`,
        })
    }
}

export const getFilteredCarTypeFilter = (carTypeFilter): CarType[] =>
    Object.keys(carTypeFilter).filter((key) => carTypeFilter[key].value) as CarType[]

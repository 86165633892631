import { useEffect, useReducer } from 'react'
import { CarType } from '@service/booking.types'
import { useTranslation } from 'next-i18next'
import { getFilteredCarTypeFilter } from '@components/modules/Car/utils'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'
import { CarFilterActions } from '@util/enums'

type CarTypeFilter = Record<string, { i18n: string; value?: boolean; checked?: boolean }>

type CarTypeFilterAction =
    | {
          type: CarFilterActions.input
          name: string
          value?: boolean
          checked?: boolean
      }
    | {
          type: CarFilterActions.init
          value: CarTypeFilter
      }

const filterReducer = (state: CarTypeFilter, action: CarTypeFilterAction) => {
    switch (action.type) {
        case CarFilterActions.input:
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    value: action.value,
                    checked: action.checked,
                },
            }
        case CarFilterActions.init:
            return action.value
        default:
            return state
    }
}

const initCarTypeFilter = (t, carType?: CarType[]) => {
    return {
        coupe: { i18n: t('carFilterTypeCoupe', 'Coupe'), value: carType?.includes(CarType.COUPE) },
        convertible: {
            i18n: t('carFilterTypeConvertible', 'Convertible'),
            value: carType?.includes(CarType.CONVERTIBLE),
        },
        crossover: {
            i18n: t('carFilterTypeCrossover', 'Crossover'),
            value: carType?.includes(CarType.CROSSOVER),
        },
        hatchback: {
            i18n: t('carFilterTypeHatchback', 'Hatchback'),
            value: carType?.includes(CarType.HATCHBACK),
        },
        sedan: { i18n: t('carFilterTypeSedan', 'Sedan'), value: carType?.includes(CarType.SEDAN) },
        station_wagon: {
            i18n: t('carFilterTypeStationWagon', 'Station wagon'),
            value: carType?.includes(CarType.STATION_WAGON),
        },
        suv: { i18n: t('carFilterTypeSUV', 'SUV'), value: carType?.includes(CarType.SUV) },
        pickup: { i18n: t('carFilterTypePickup', 'Pickup'), value: carType?.includes(CarType.PICKUP) },
    }
}

export const useCarTypeFilterValues = (carType?: CarType[]) => {
    const { t } = useTranslation()
    const carTypeConfig = useDeepCompareMemo(() => initCarTypeFilter(t, carType), [carType])
    const [carTypeFilter, carTypeDispatch] = useReducer(filterReducer, carTypeConfig)

    useEffect(() => {
        carTypeDispatch({ type: CarFilterActions.init, value: carTypeConfig })
    }, [carTypeConfig])

    const carTypeFilterQuery = getFilteredCarTypeFilter(carTypeFilter)

    return {
        carTypeFilter,
        carTypeDispatch,
        carTypeFilterQuery,
    }
}

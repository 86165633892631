import { useEffect, useReducer } from 'react'
import { useQuery } from 'react-query'
import { CarFilterActions, CityCode } from '@util/enums'
import { defaultCityCode } from '@util/config'
import { ProductType } from '@service/configuration.types'
import { useLocaleConfig } from '@contexts/config'
import { bookingService } from '@service/booking'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'

type CarMakeFilterAction =
    | {
          type: CarFilterActions.set | CarFilterActions.init
          value: number[]
      }
    | {
          type: CarFilterActions.reset
      }

const filterReducer = (state: { value: number[] }, action: CarMakeFilterAction) => {
    switch (action.type) {
        case CarFilterActions.set: {
            return {
                ...state,
                value: action.value,
            }
        }
        case CarFilterActions.init:
            return { value: action.value }
        case CarFilterActions.reset:
            return { value: [] }

        default:
            return state
    }
}

export const useCarMakeFilterValues = (productType?: ProductType, carMake?: number[]) => {
    const { citySettings } = useLocaleConfig()

    const cityCode = CityCode[citySettings?.cityCode || defaultCityCode]
    const { data: makes } = useQuery(['makes', productType, cityCode], () =>
        bookingService.getMakes({
            cityCode: cityCode,
            productType: productType || ProductType.DAILY,
        }),
    )
    const carMakeConfig = useDeepCompareMemo(() => carMake, [carMake])

    const [filter, dispatch] = useReducer(filterReducer, { value: carMakeConfig || [] })

    useEffect(() => {
        carMakeConfig && dispatch({ type: CarFilterActions.init, value: carMakeConfig })
    }, [carMakeConfig])

    return {
        carMakeFiltered: filter.value.map((x) => +x),
        carMakeDispatch: dispatch,
        carMakeData: makes?.data,
    }
}

import { useEffect, useMemo, useReducer } from 'react'
import { GearBoxType } from '@service/booking.types'
import { useDeepCompareMemo } from '@hooks/useDeepCompare'
import { CarFilterActions } from '@util/enums'

type CarGearboxFilterAction =
    | {
          type: CarFilterActions.set | CarFilterActions.init
          value: string[]
      }
    | {
          type: CarFilterActions.reset
      }

const filterReducer = (state: { value: string[] }, action: CarGearboxFilterAction) => {
    switch (action.type) {
        case CarFilterActions.set:
            return {
                ...state,
                value: action.value,
            }
        case CarFilterActions.init:
            return { value: action.value }
        case CarFilterActions.reset:
            return { value: [] }
        default:
            return state
    }
}

export const useCarGearboxFilterValues = (carGearboxType?: string[]) => {
    const carGearboxTypeConfig = useDeepCompareMemo(() => carGearboxType, [carGearboxType])

    const [filter, dispatch] = useReducer(filterReducer, { value: carGearboxType || [] })

    useEffect(() => {
        carGearboxTypeConfig && dispatch({ type: CarFilterActions.set, value: carGearboxTypeConfig })
    }, [carGearboxTypeConfig])

    const gearBoxTypes = useMemo(
        () =>
            Object.keys(GearBoxType).map((gearBoxType) => ({
                id: gearBoxType,
                name: gearBoxType,
            })),
        [],
    )

    return {
        carGearboxFiltered: filter.value,
        carGearboxDispatch: dispatch,
        carGearboxData: gearBoxTypes,
    }
}

import { CalendarIcon } from '@global/icons/Calendar'
import { useLocaleConfig } from '@contexts/config'
import { useSearch } from '@contexts/search'
import styled from 'styled-components'
import { Label } from './TimeFilterDaily'
import { DateTimeSelectorTypes, GATrackingActions, GATrackingCategories } from '@util/enums'
import { trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'

type TimeFilterDateTimeRowProps = {
    type: DateTimeSelectorTypes
    label?: string
    handleCalendarModalClick: () => void
    showTime?: boolean
    labelColor?: string
}
export const TimeFilterMonthly = ({
    type,
    label,
    handleCalendarModalClick,
    labelColor,
}: TimeFilterDateTimeRowProps) => {
    const { formatDate } = useLocaleConfig()
    const { fromDate, toDate } = useSearch()
    const isDesktop = useMediaQuery(device.laptop)
    const { user } = useAuth()

    const date = type === DateTimeSelectorTypes.from ? fromDate : toDate

    return (
        <Flex>
            <InputWithLabelAndIcon>
                {label && <Label color={labelColor}>{label}</Label>}
                <InputButton
                    onClick={() => {
                        handleCalendarModalClick()
                        trackEvent({
                            action: GATrackingActions.H_M_SELECT_DATE,
                            category: GATrackingCategories.DATE,
                            value: {
                                userId: user?.userId || '',
                                careemId: user?.careemId || '',
                                isDesktop: isDesktop,
                            },
                        })
                    }}
                >
                    <p className="dateTimeSelectorSpan" style={{ whiteSpace: 'nowrap' }}>
                        {formatDate(date ?? new Date())}
                    </p>
                    <span>
                        <CalendarIcon color={'var(--primaryColor)'} />
                    </span>
                </InputButton>
            </InputWithLabelAndIcon>
        </Flex>
    )
}

export const Flex = styled.div<{ direction?: string }>`
    display: flex;
    flex-direction: ${({ direction = 'row' }) => direction};
    justify-content: center;
    gap: 1rem;
    width: 100%;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const InputWithLabelAndIcon = styled.div<{ withoutLabel?: boolean }>`
    width: 100%;
    margin-bottom: 1rem;
    min-width: 120px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    ${({ withoutLabel = false }) => (withoutLabel ? 'padding-top: 22px' : '0')};
`

export const InputButton = styled.button<{ fontWeight?: number }>`
    width: 100%;
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    background: var(--primaryBackgroundColor);

    border: 1px solid transparent;
    border-radius: 4px;

    font-weight: ${(props) => props.fontWeight};
    font-size: 16px;

    svg {
        flex: 1;
    }

    &:focus {
        border: 1px solid red;
        color: var(--primaryColor);
    }
`
InputButton.defaultProps = {
    fontWeight: 700,
}

import React from 'react'
import { Booking } from '@service/booking.types'
import { useTranslation } from 'next-i18next'
import { ClockIcon } from '@global/icons/Clock'
import { displayTimeRange, getDefaultExtraHours } from '@util/functions'
import { MapPin } from '@global/icons/MapPin'
import Link from 'next/link'
import {
    Body,
    BoldText,
    Bottom,
    CarComponent,
    Column,
    DateRow,
    DateSection,
    DesktopWrapper,
    getFormattedAddress,
    Row,
    Separator,
    Text,
    Title,
} from '@components/CurrentBookingsWidget/styles'
import { useLocaleConfig } from '@contexts/config'
import { getGoogleMapLink } from '.'
import { ExtendButton } from '@components/CurrentBookingsWidget/ExtendButton'
import { Status } from './Status'
import { useVerification } from '@hooks/useVerification'
import { useSwiperAutoHeight } from './hooks'

export const maxWidthOfTheDateRange = 270

export const PendingBookingDesktopView: React.FC<{ booking: Booking; isD2D: boolean }> = ({ booking, isD2D }) => {
    const { t } = useTranslation()
    const { city, isRTL } = useLocaleConfig()
    const { verificationStatus } = useVerification()
    const address = isD2D && booking.d2dHandbackAddress ? booking.d2dHandbackAddress : booking.selfPickupHandbackAddress
    const formattedAddress = getFormattedAddress(address)
    const handoverTime = booking.handoverTime ? new Date(booking.handoverTime) : undefined
    useSwiperAutoHeight()
    return (
        <DesktopWrapper>
            <Column isRTL={isRTL}>
                <Title>
                    <Link href={`/${city}/booking/${booking.bookingId}`}>
                        <span>{t('bookingWidget.bookingId', 'Booking ID')}:</span>
                        <span> </span>
                        <span data-testid="booking-widget_booking-id">{booking.bookingId}</span>
                    </Link>
                </Title>
                <Body>
                    <DateRow maxWidth={maxWidthOfTheDateRange}>
                        <DateSection date={booking.handoverTime} dataTestId="booking-widget_handover-time" />
                        <Separator />
                        <DateSection date={booking.handbackTime} dataTestId="booking-widget_handback-time" />
                    </DateRow>
                    <CarComponent carDetails={booking.carDetails} />
                </Body>
                <Bottom>
                    <ExtendButton booking={booking} city={city} />
                </Bottom>
            </Column>
            <Column justify="start" isRTL={isRTL}>
                <Title>{t('bookingWidget.status', 'Status')}</Title>
                <Status status={booking.status} verificationStatus={verificationStatus} isD2D={isD2D} />
            </Column>
            <Column isRTL={isRTL}>
                <Title data-testid="booking-widget_delivery-type">
                    {isD2D ? t('bookingWidget.delivery', 'Delivery') : t('bookingWidget.pickUp', 'Pickup')}
                </Title>
                <Body>
                    {handoverTime && (
                        <Row>
                            <ClockIcon color={'var(--primaryColor)'} />{' '}
                            <BoldText data-testid="booking-widget_handback-time">
                                {displayTimeRange(handoverTime, getDefaultExtraHours(isD2D)) ||
                                    t('customerBookingWidgetNotSet', 'not set')}
                            </BoldText>
                        </Row>
                    )}
                    <Row>
                        <MapPin />
                        <Link href={getGoogleMapLink(address.latitude, address.longitude)}>
                            <Text>{formattedAddress}</Text>
                        </Link>
                    </Row>
                </Body>
                <Bottom />
            </Column>
        </DesktopWrapper>
    )
}

import styled from 'styled-components'

const CarSvg = styled.svg<{ color?: string }>`
    color: ${({ color = `rgba(0, 0, 0, 0.8)` }) => color};
`

export const Car = ({ color }: { color?: string }) => (
    <CarSvg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" color={color}>
        <path
            d="M15.92 1.01024C15.72 0.420244 15.16 0.000244141 14.5 0.000244141H3.5C2.84 0.000244141 2.29 0.420244 2.08 1.01024L0 7.00024V15.0002C0 15.5502 0.45 16.0002 1 16.0002H2C2.55 16.0002 3 15.5502 3 15.0002V14.0002H15V15.0002C15 15.5502 15.45 16.0002 16 16.0002H17C17.55 16.0002 18 15.5502 18 15.0002V7.00024L15.92 1.01024ZM3.5 11.0002C2.67 11.0002 2 10.3302 2 9.50024C2 8.67024 2.67 8.00024 3.5 8.00024C4.33 8.00024 5 8.67024 5 9.50024C5 10.3302 4.33 11.0002 3.5 11.0002ZM14.5 11.0002C13.67 11.0002 13 10.3302 13 9.50024C13 8.67024 13.67 8.00024 14.5 8.00024C15.33 8.00024 16 8.67024 16 9.50024C16 10.3302 15.33 11.0002 14.5 11.0002ZM2 6.00024L3.5 1.50024H14.5L16 6.00024H2Z"
            fill="currentColor"
        />
    </CarSvg>
)

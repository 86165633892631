import { device } from '@util/responsive'
import { FilterIcon } from '@global/icons/Filter'
import React, { useImperativeHandle, useState } from 'react'
import { trackEvent } from '@util/ga'
import { useSearch } from '@contexts/search'
import { useMediaQuery } from '@hooks/mediaQuery'
import dynamic from 'next/dynamic'
import { Modal } from '@components/global/Modal'
import styled from 'styled-components'
import { Spinner } from '@components/global/Spinner'
import { MobileFilterIcon } from '@components/global/icons/MobileFIlterIcon'
import { useTranslation } from 'react-i18next'

const DesktopFilterModal = dynamic(
    () => import('./DesktopFilterModal').then(({ DesktopFilterModal }) => DesktopFilterModal),
    { ssr: false, loading: () => <Spinner /> },
)

const MobileFilterModal = dynamic(
    () => import('./MobileFilterModal').then(({ MobileFilterModal }) => MobileFilterModal),
    { ssr: false, loading: () => <Spinner /> },
)

export const Filter = React.forwardRef<any, { filterCount?: number; removeForMobile?: boolean }>(
    ({ filterCount, removeForMobile }, ref) => {
        const { t } = useTranslation()
        const isDesktop = useMediaQuery(device.laptop)
        const [isOpen, setIsOpen] = useState(false)
        const openFromParent = () => {
            setIsOpen(true)
        }
        useImperativeHandle(ref, () => ({
            openFromParent,
        }))
        const { datePickerOverlay, setDatepickerOverlay } = useSearch()
        return (
            <div>
                {isDesktop ? (
                    <FilterWrapper
                        data-testid="filter-button"
                        onClick={() => {
                            setIsOpen(true)
                            trackEvent({
                                action: 'Filters-open',
                                category: 'BrowseDR',
                            })
                            datePickerOverlay && setDatepickerOverlay(false)
                        }}
                    >
                        <FilterIcon />
                    </FilterWrapper>
                ) : (
                    <FilterMobileWrapper
                        data-testid="filter-button"
                        onClick={() => {
                            setIsOpen(true)
                            trackEvent({
                                action: 'Filters-open',
                                category: 'BrowseDR',
                            })
                            datePickerOverlay && setDatepickerOverlay(false)
                        }}
                        active={removeForMobile ?? false}
                    >
                        <MobileFilterIcon />
                        {typeof filterCount === 'number' && filterCount > 0 && (
                            <FilterCountBadge>{filterCount}</FilterCountBadge>
                        )}
                        <FilterText>{t('MobileFilerText', 'Filters')}</FilterText>
                    </FilterMobileWrapper>
                )}
                {isOpen &&
                    (isDesktop ? (
                        <Modal setModal={setIsOpen} wrapperComponent={ModalContainer}>
                            <DesktopModalWrapper>
                                <DesktopFilterModal setModal={setIsOpen} />
                            </DesktopModalWrapper>
                        </Modal>
                    ) : (
                        <Modal wrapperComponent={ModalWrapper} setModal={setIsOpen}>
                            <MobileFilterModal setModal={setIsOpen} />
                        </Modal>
                    ))}
            </div>
        )
    },
)

Filter.displayName = 'Filter'

const FilterCountBadge = styled.div`
    top: -5px;
    right: -5px;
    position: absolute;
    background-color: red;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
`

const FilterWrapper = styled.div`
    position: fixed;
    z-index: 30;
    bottom: 1rem;
    inset-inline-end: 1rem;
    background: var(--primaryColor);
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-color: rgb(203 71 71 / 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    border-radius: 50px;
    cursor: pointer;
    @media ${device.laptop} {
        display: 'flex';
        position: 'absolute';
        inset-inline-end: '-8rem';
        top: '50%';
    }
`
const FilterMobileWrapper = styled.div<{ active: boolean }>`
    position: relative;
    right: 0px;
    background: white;
    display: ${(props) => (props.active ? 'none' : 'flex;')};
    width: 90px;
    height: 40px;
    border: 1px solid #e0e0e3;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 18px;
    @media ${device.laptop} {
        display: 'flex';
        position: 'absolute';
        inset-inline-end: '-8rem';
        top: '50%';
    }
`
const FilterText = styled.div`
    margin-left: 12px;
    font-size: 14px;
    font-weight: 600;
`
const DesktopModalWrapper = styled.div`
    background: white;
    height: fit-content;
    width: 40vw;
    min-width: 450px;
    max-width: 600px;
    max-height: var(--maxModalHeight);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
`
const ModalWrapper = styled.div`
    z-index: 50;
    inset: 0;
    background: white;
    padding-bottom: 5rem;
`

import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import useMountEffect from '@hooks/useMountEffect'

export const Portal: React.FC<{ isOpen?: boolean; children: ReactNode }> = ({ children }) => {
    // const [mounted, setMounted] = useState(false)
    const node = document.getElementById('portal-root') // defined in _app.tsx

    useMountEffect(() => {
        // setMounted(true)
        node && node.classList.add('portal-open')

        return () => {
            // setMounted(false)
            node && node.classList.remove('portal-open')
        }
    })

    // TODO this is problematic for modals where we use form validation from react hook form
    // we'll have to refactor the way we handle modal styles to not have them defined globally
    // if (!mounted) {
    //     node && node.classList.remove('portal-open')
    //     return null
    // }

    return node ? createPortal(children, node) : null
}

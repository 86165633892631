import { useCommon } from '@contexts/common'
import { ProductType } from '@service/configuration.types'
import { useCallback } from 'react'

export const useProductSwitcher = () => {
    const { changeProductTo } = useCommon()

    const onTabSelected = useCallback(
        (productType: ProductType) => {
            changeProductTo(productType)
        },
        [changeProductTo],
    )

    return { onTabSelected }
}

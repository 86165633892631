export const WrenchIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0907 1.90995C9.08074 -0.100046 6.07074 -0.510046 3.65074 0.669954L7.28074 4.29995C7.67074 4.68995 7.67074 5.31995 7.28074 5.70995L5.69074 7.29995C5.30074 7.69995 4.67074 7.69995 4.28074 7.29995L0.650736 3.66995C-0.519264 6.09995 -0.109264 9.08995 1.90074 11.1C3.76074 12.96 6.48074 13.45 8.79074 12.58L16.7507 20.54C17.7807 21.57 19.4407 21.57 20.4607 20.54C21.4907 19.51 21.4907 17.85 20.4607 16.83L12.5407 8.89995C13.4607 6.55995 12.9807 3.79995 11.0907 1.90995Z"
                fill="currentColor"
            />
        </svg>
    )
}
